<template>
  <div class="d-flex-column flex-10">
    <div class="flex-10">

      <Alert :visible="alertVisible" @close="alertVisible = false">
        Your profile has been successfully updated.
      </Alert>
      <h4 class="align-text-center">
        Register another to the same entity or a different one?
      </h4>
      <div class="mt-2">
        <LongLink :text="'Same entity'" @go="registerSame"/>
        <LongLink :text="'Different one'" @go="registerAnother"/>
      </div>
    </div>

    <div class="flex-0">
      <el-card>
        <el-button
            class="full-width button-light button-thin btn"
            @click="$router.push({name: 'payment'})"
        >
          <span class="thick">Cancel and go to payment</span>
        </el-button>
      </el-card>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import LongLink from '@/components/LongLink.vue';
import Alert from '@/components/card/Alert.vue';

export default {
  name: 'RegisterAnother',
  components: {
    Alert,
    LongLink,
  },
  data() {
    return {
      // alertVisible: true,
      alertVisible: false,
    };
  },
  computed: {
    ...mapGetters('registration', [
      'completed',
    ]),
  },
  methods: {
    ...mapActions('registration', [
      'updateCurrent',
    ]),
    registerSame() {
      // Default to latest registration
      const lastRego = this.completed[0];
      if (lastRego && lastRego.type && lastRego.entity) {
        this.updateCurrent({
          type: lastRego.type,
          entity: lastRego.entity,
        });
        this.$router.push({ name: 'chooseprofile' });
      } else {
        this.registerAnother();
      }
    },
    registerAnother() {
      this.$router.push({ name: 'clubsearch' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
    padding: 10px 0 10px !important;
    overflow-wrap: break-spaces;
  }
  .payment {
  }
  .thick {
    overflow-wrap: break-spaces;
    letter-spacing: 0.1em;
    color: #262626;
    font-size: .9em;
  }
</style>
